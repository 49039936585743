<template>
    <div class="task-list">
        <div class="task-list-head">
            <!-- <el-form-item label="任务ID：">
                <el-input
                    v-model="searchData.searchId"
                    placeholder="请输入任务ID"
                    clearable
                />
            </el-form-item> -->
            <!-- <el-form-item label="任务名称：">
                <el-input
                    v-model="searchData.taskName"
                    placeholder="输入计划名称"
                    clearable
                />
            </el-form-item>
            <el-form-item label="小程序：">
                <el-select
                    v-model="searchData.selectedMini"
                    class="m-2"
                    placeholder="选择小程序"
                >
                    <el-option
                        v-for="item in userInfo.organizations"
                        :key="item.appId"
                        :label="item.title"
                        :value="item.appId"
                    />
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="创建日期：">
                <el-date-picker
                    v-model="searchData.dateList"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                />
            </el-form-item> -->
            <el-form-item>
                <!-- <el-button type="primary" :icon="Search">搜索</el-button> -->
                <!-- <el-button>重置</el-button> -->
                <el-button
                    type="primary"
                    :icon="CirclePlus"
                    @click="handleAppendTask"
                    >添加任务</el-button
                >
            </el-form-item>
        </div>
        <div class="task-list-table" style="width: 100%">
            <el-table :data="tableList || []" border style="width: 100%">
                <el-table-column
                    prop="title"
                    label="任务名称"
                ></el-table-column>
                <el-table-column
                    prop="miniName"
                    label="小程序"
                ></el-table-column>
                <el-table-column prop="createTime" label="创建时间" />
                <el-table-column label="操作">
                    <template #default="scope">
                        <div class="table-edit">
                            <div @click="handleLookingTask(scope.row)">
                                查看
                            </div>
                            <div @click="handleEditTask(scope.row)">编辑</div>
                            <div @click="handleDeleteTask(scope.row)">删除</div>
                            <div @click="handleCopyTask(scope.row)">复制</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
            v-model="modalVisible"
            :title="appendContent.id ? '修改链接' : '新增链接'"
            width="40%"
            :before-close="handleCloseModal"
        >
            <div class="content-modal">
                <el-form-item label="任务名称：" label-width="100">
                    <el-input
                        v-model="appendContent.title"
                        placeholder="请输入任务名称"
                    />
                </el-form-item>
                <el-form-item label="上传海报：" label-width="100">
                    <div class="upload-cover">
                        <div class="upload-cover-desc">
                            温馨提示：图片大小不能超过5MB（上传后点击可放大查看）
                        </div>
                        <div class="upload-cover-image">
                            <el-image
                                v-if="appendContent.imgUrl"
                                :src="appendContent.imgUrl"
                                class="avatar"
                                :zoom-rate="1.2"
                                :preview-src-list="[appendContent.imgUrl]"
                                :initial-index="4"
                                hide-on-click-modal
                                fit="cover"
                            />
                            <el-button
                                v-if="appendContent.imgUrl"
                                type="primary"
                                @click="handleClearImage"
                                style="margin-left: 10px"
                                >修改图片</el-button
                            >
                            <div
                                v-if="!appendContent.imgUrl"
                                style="width: 100%"
                            >
                                <el-upload
                                    class="avatar-uploader"
                                    action="#"
                                    :show-file-list="false"
                                    ref="uploadRef"
                                    multiple
                                    :limit="1"
                                    :http-request="
                                        (file) => handleUploadImg(file)
                                    "
                                >
                                    <div class="content">
                                        <el-icon class="avatar-uploader-icon">
                                            <Plus />
                                        </el-icon>
                                        <div>上传图片</div>
                                    </div>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="小程序：" label-width="100">
                    <el-select
                        v-model="appendContent.orgId"
                        class="m-2"
                        placeholder="选择小程序"
                    >
                        <el-option
                            v-for="item in userInfo.organizations"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <div class="content-modal-btn">
                    <el-button @click="handleCloseModal">取消</el-button>
                    <el-button type="primary" @click="handleSaveContent"
                        >保存</el-button
                    >
                </div>
            </div>
        </el-dialog>
        <el-dialog v-model="QRcodeModalVisible" title="查看" width="40%">
            <div class="detail-preview" v-if="miniQRcode">
                <div class="title">预览二维码</div>
                <el-image
                    :src="miniQRcode"
                    class="avatar"
                    :zoom-rate="1.2"
                    :preview-src-list="[miniQRcode]"
                    :initial-index="4"
                    hide-on-click-modal
                    fit="cover"
                />
            </div>
            <div class="detail-preview-text">
                <div class="title">
                    {{
                        miniQRcode ? "点击图片可放大查看二维码" : "小程序待发布"
                    }}
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive } from "vue";
import { Search, CirclePlus, Plus } from "@element-plus/icons-vue";
import { getUserInfo } from "@/services/modules/user";
import {
    orgContentList,
    orgContentSave,
    createMpQRcode,
} from "@/services/modules/organization";
import { formatDate, copyText } from "@/utils/utils";
import Uploader from "@/utils/upload/ossupload";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
    setup() {
        const miniQRcode = ref("");
        const QRcodeModalVisible = ref(false);
        const uploadRef = ref();
        const modalVisible = ref(false);
        const userInfo = reactive({});
        const appendContent = reactive({
            title: "",
            orgId: "",
            imgUrl: "",
        });
        const searchData = reactive({
            searchId: "",
            taskName: "",
            miniProgram: [],
            dateList: "",
            selectedMini: "",
        });
        const tableList = ref([]);

        const orgId = ref("");
        const task = reactive({ list: [] });

        const handleAppendTask = () => {
            modalVisible.value = true;
        };

        const handleLookingTask = (item) => {
            console.log("item", item);
            QRcodeInit(item, "preview");
        };

        const handleEditTask = (item) => {
            modalVisible.value = true;
            Object.assign(appendContent, JSON.parse(JSON.stringify(item)));
        };

        const handleDeleteTask = (item) => {
            ElMessageBox.confirm("删除后不可恢复确认删除吗？", "温馨提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let params = {
                        ...item,
                        deleted: true,
                    };
                    const res = await orgContentSave(params);
                    if (res.code == 1) {
                        ElMessage.success("删除成功");
                        handleCloseModal();
                        orgContentInit();
                        return;
                    }
                })
                .catch(() => {});
        };

        const handleCopyTask = (item) => {
            copyText(`pages/index/index?scene=${item.id}`);
            ElMessage.success("复制小程序链接成功");
        };

        const handleCloseModal = () => {
            modalVisible.value = false;
            Object.assign(appendContent, {
                title: "",
                orgId: "",
                imgUrl: "",
            });
        };

        const userInfoInit = async () => {
            getUserInfo()
                .then((res) => {
                    let info = res.data;
                    if (info) {
                        info.organizations = info.organizations
                            .map((item) => {
                                return {
                                    ...item,
                                    appId:
                                        item.authorizer &&
                                        item.authorizer.length > 0
                                            ? item.authorizer[0].appId
                                            : "",
                                    date: formatDate(
                                        item.createDate,
                                        "yyyy-MM-dd hh:mm:ss"
                                    ),
                                };
                            })
                            .filter((fItem) => fItem.appId);
                    }
                    Object.assign(userInfo, res.data);
                    orgContentInit();
                    console.log("userInfo", userInfo);
                })
                .catch((err) => {
                    Object.assign(
                        userInfo,
                        localStorage.getItem("userInfo")
                            ? JSON.parse(localStorage.getItem("userInfo"))
                            : {}
                    );
                    console.log("userInfo", userInfo);
                    orgContentInit();
                });
        };

        const orgContentInit = async () => {
            let params = {
                orgId: orgId.value,
            };
            const res = await orgContentList(params);
            if (res.code == 1) {
                if (res.data.length > 0) {
                    tableList.value = res.data.map((item) => {
                        return {
                            ...item,
                            miniName:
                                userInfo.organizations.find(
                                    (fItem) => fItem.id == item.orgId
                                )?.title || "",
                        };
                    });
                }
            }
        };

        const QRcodeInit = async (item, codeType) => {
            console.log("userInfo", userInfo);
            const currentOrg = userInfo.organizations.find(
                (fItem) => fItem.id == item.orgId
            );
            console.log("currentOrg", currentOrg);
            if (!currentOrg && currentOrg.authorizer.length === 0) return;
            let params = {
                appId: currentOrg.authorizer[0].appId,
                appName: currentOrg.title,
                param: `${item.id}`,
                path: "pages/index/index",
                version: 0,
            };

            const res = await createMpQRcode(params);
            if (res.data) {
                miniQRcode.value = `data:image/png;base64,${res.data}`;
                if (codeType == "preview") {
                    QRcodeModalVisible.value = true;
                }
            }
        };

        const handleUploadImg = (params) => {
            Uploader.put(
                params.file,
                params.file.type,
                async (url) => {
                    console.log("----", url);
                    appendContent.imgUrl = url;
                    if (uploadRef.value) {
                        uploadRef.value.clearFiles();
                    }
                    ElMessage.success("上传成功");
                },
                (err) => {
                    console.log("----", err);
                    ElMessage.error("上传失败");
                }
            );
        };

        const handleClearImage = () => {
            appendContent.imgUrl = "";
        };

        const handleSaveContent = async () => {
            if (!appendContent.imgUrl) {
                return ElMessage.error("请先上传海报图片");
            }
            if (!appendContent.title) {
                return ElMessage.error("请输入任务名称");
            }
            if (!appendContent.orgId) {
                return ElMessage.error("请选择小程序");
            }

            let params = {
                ...appendContent,
            };

            const res = await orgContentSave(params);
            if (res.code == 1) {
                ElMessage.success("保存成功");
                handleCloseModal();
                orgContentInit();
                return;
            }

            ElMessage.error(res.msg || res.message || "保存失败");
        };

        userInfoInit();

        return {
            Plus,
            Search,
            CirclePlus,
            searchData,
            handleLookingTask,
            task,
            userInfo,
            modalVisible,
            handleCloseModal,
            appendContent,
            orgId,
            handleUploadImg,
            uploadRef,
            handleClearImage,
            handleSaveContent,
            tableList,
            handleEditTask,
            handleDeleteTask,
            handleCopyTask,
            QRcodeModalVisible,
            miniQRcode,
            handleAppendTask,
        };
    },
};
</script>
<style lang="scss">
.task-list-table {
    .el-table__header-wrapper {
        .el-table__cell {
            background: #f6f8fc;
        }
    }

    .el-table__row {
        &:nth-child(2n) {
            background: #f6f8fc;
        }
    }
}
</style>
<style lang="scss" scoped>
.task-list {
    background: #fff;
    border-radius: 5px;
    width: 100%;

    &-head {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;

        .el-form-item {
            margin-right: 20px;
        }
    }
}

.content-modal {
    .el-form-item {
        width: 500px;
    }

    &-btn {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}

.upload-cover {
    &-desc {
        font-size: 14px;
        color: #999;
    }

    &-image {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        position: relative;
        box-sizing: border-box;

        &:hover {
            .close {
                display: flex !important;
                justify-content: center;
                align-items: center;
            }
        }

        .avatar {
            width: 200px;
            height: 200px;
        }

        .close {
            width: 200px;
            height: 200px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            z-index: 9;
            display: none;
            cursor: pointer;

            background: rgba($color: #000000, $alpha: 0.5);
            color: #fff;
            transition: all 0.5s;

            .el-icon {
                font-size: 20px;
            }
        }

        .avatar-uploader {
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e0e6ed;

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .el-icon {
                    font-size: 30px;
                    font-weight: bold;
                    color: #999;
                }

                div {
                    font-size: 18px;
                    margin-top: 5px;
                    color: #999;
                }
            }
        }
    }
}

.task-list-table {
    padding: 16px;
    box-sizing: border-box;

    .table-edit {
        display: flex;
        justify-content: space-between;
        align-content: center;

        div {
            color: #3572ff;
            font-size: 14px;
            cursor: pointer;
        }
    }
}

.detail-preview {
    text-align: center;

    .title {
        color: #111;
        font-size: 14px;
    }

    .el-image {
        width: 240px;
        height: 240px;
        background: red;
        display: block;
        margin: 20px auto;
    }
}

.detail-preview-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .copy {
        color: #3572ff;
        font-size: 14px;
        cursor: pointer;
        margin-left: 10px;
    }
}
</style>